<template>
    <phi-page class="page-launcher" color="transparent" :style="{backgroundColor: settings.color}">

        <section class="phi-card header" v-if="user">
            <div class="logo">
                <img :src="settings.logo" :alt="settings.title" :style="{backgroundColor: settings.color}" />
            </div>

            <router-link to="/id">
                <div class="phi-media">
                    <div class="phi-media-figure phi-avatar">
                        <person-avatar :person="user"></person-avatar>
                    </div>
                    <h1 class="phi-media-body" v-text="user.firstName + ' ' + user.lastName"></h1>
                </div>
            </router-link>
        </section>

        <section class="navigation">
			<div class="phi-card">

                <!-- teaching -->
				<router-link :to="'/teaching/'+user.id" class="phi-media" v-if="deck.isAllowed.teaching">
                    <mu-icon value="book" class="phi-media-figure" />
                    <span class="phi-media-body">{{ $t("launcher.teaching") }}</span>
                    <mu-icon value="keyboard_arrow_right" class="phi-media-right" />
                </router-link>

                <!-- Billing -->
				<router-link :to="'/billing/'+user.id" class="phi-media" v-if="deck.isAllowed.billing">
                    <mu-icon value="payment" class="phi-media-figure" />
                    <span class="phi-media-body">{{ $t("launcher.billing") }}</span>
                    <mu-icon value="keyboard_arrow_right" class="phi-media-right" />
                </router-link>

                <!-- Transportation -->
                <!--
                    Si el usuario tiene permiso tanto a acompañamiento como a mapa,
                    mostrar el menu de transporte como una lista desplegable.
                    De lo contrario mostrar unicamente un item "transporte"
                -->
                <template v-if="deck.isAllowed.transport && deck.isAllowed.geoFeed">

                    <a class="phi-media group" @click="toggleDrawer('transport')">
                        <mu-icon value="directions_bus" class="phi-media-figure" />
                        <span class="phi-media-body">{{ $t("launcher.transport") }}</span>
                        <mu-icon :value="drawers.transport ? 'keyboard_arrow_up' : 'keyboard_arrow_down'" class="phi-media-right" />
                    </a>
                    <phi-drawer :open="drawers.transport">
                        <div class="submenu transport last">
                            <router-link to="/transport/routes" class="phi-media">
                                <mu-icon value="supervised_user_circle" class="phi-media-figure" />
                                <span class="phi-media-body">{{ $t("noun.targets") }}</span>
                                <mu-icon value="keyboard_arrow_right" class="phi-media-right" />
                            </router-link>

                            <router-link to="/geo/feed" class="phi-media">
                                <mu-icon value="location_on" class="phi-media-figure" />
                                <span class="phi-media-body">{{ $t("noun.map") }}</span>
                                <mu-icon value="keyboard_arrow_right" class="phi-media-right" />
                            </router-link>
                        </div>
                    </phi-drawer>

                </template>
                <template v-else>
                    <router-link to="/transport/routes" class="phi-media" v-if="deck.isAllowed.transport">
                        <mu-icon value="directions_bus" class="phi-media-figure" />
                        <span class="phi-media-body">{{ $t("launcher.transport") }}</span>
                        <mu-icon value="keyboard_arrow_right" class="phi-media-right" />
                    </router-link>

                    <router-link to="/geo/feed" class="phi-media" v-if="deck.isAllowed.geoFeed">
                        <mu-icon value="directions_bus" class="phi-media-figure" />
                        <span class="phi-media-body">{{ $t("launcher.transport") }}</span>
                        <mu-icon value="keyboard_arrow_right" class="phi-media-right" />
                    </router-link>
                </template>


                <!-- Messaging -->
                <a class="phi-media group" @click="toggleDrawer('messaging')">
                    <mu-icon value="message" class="phi-media-figure" />
                    <span class="phi-media-body">{{ $t("launcher.messaging") }}</span>
                    <mu-icon :value="drawers.messaging ? 'keyboard_arrow_up' : 'keyboard_arrow_down'" class="phi-media-right" />
                </a>
                <phi-drawer :open="drawers.messaging">
                    <div class="submenu messaging last">
                        <router-link to="/inbox" class="phi-media">
                            <mu-icon value="inbox" class="phi-media-figure" />
                            <span class="phi-media-body">{{ $t("noun.inbox") }}</span>
                            <mu-icon value="keyboard_arrow_right" class="phi-media-right" />
                        </router-link>

                        <router-link to="/sent" class="phi-media">
                            <mu-icon value="send" class="phi-media-figure" />
                            <span class="phi-media-body">{{ $t("noun.sent.plural") }}</span>
                            <mu-icon value="keyboard_arrow_right" class="phi-media-right" />
                        </router-link>

                        <router-link to="/archive" class="phi-media">
                            <mu-icon value="folder" class="phi-media-figure" />
                            <span class="phi-media-body">{{ $t("noun.archived.plural") }}</span>
                            <mu-icon value="keyboard_arrow_right" class="phi-media-right" />
                        </router-link>

                        <router-link to="/trash" class="phi-media">
                            <mu-icon value="delete" class="phi-media-figure" />
                            <span class="phi-media-body">{{ $t("noun.trash") }}</span>
                            <mu-icon value="keyboard_arrow_right" class="phi-media-right" />
                        </router-link>
                    </div>
                </phi-drawer>

			</div>
        </section>

        <!-- links a bloques -->
        <!-- <section class="blocks" v-if="blockLinks">
			<div class="phi-card">
				<router-link
                    v-for="block in blockLinks"
                    :key="block.id"
                    class="phi-media"

                    :to="{name: 'cms-block', params:{blockId: block.id, title: block.title || block.label}}"
                >
                    <mu-icon :value="block.icon || 'insert_drive_file'" class="phi-media-figure" />
                    <span class="phi-media-body">{{ block.label }}</span>
                    <mu-icon value="keyboard_arrow_right" class="phi-media-right" />
                </router-link>
			</div>
        </section> -->

        <section class="school" v-if="settings.organization">
            <div class="phi-card">
                <a v-if="settings.organization.website"
                    href="#"
                    @click.prevent="window.open(settings.organization.website, '_system')"
                    class="phi-media"
                >
                    <mu-icon value="laptop" class="phi-media-figure" />
                    <div class="phi-media-body">{{ $t('launcher.Website') }}</div>
                </a>

                <a v-if="settings.organization.phone"
                    :href="'tel:' + settings.organization.phone"
                    class="phi-media"
                >
                    <mu-icon value="phone" class="phi-media-figure" />
                    <div class="phi-media-body">{{ $t('launcher.CallSchool') }}</div>
                </a>

                <a v-if="locationUrl"
                    href="#"
                    @click.prevent="window.open(locationUrl, '_system')"
                    class="phi-media"
                >
                    <mu-icon value="location_on" class="phi-media-figure" />
                    <div class="phi-media-body">{{ $t('launcher.Location') }}</div>
                </a>

                <a
                    href="#"
                    @click.prevent="window.open(phidiasUrl, '_system')"
                    class="phi-media"
                >
                    <mu-icon value="school" class="phi-media-figure" />
                    <div class="phi-media-body">Phidias</div>
                </a>

                <!-- Custom Links -->
                <a
                    v-for="link in customLinks"
                    :key="link.id"
                    href="#"
                    @click.prevent="window.open(link.url, '_system')"
                    class="phi-media"
                >
                    <mu-icon :value="link.icon" class="phi-media-figure" />
                    <div class="phi-media-body">{{ $t('StateLauncher.link.'+link.id) }}</div>
                </a>
            </div>
        </section>

        <section class="admin">
			<div class="phi-card">
				<!--
                <router-link to="/post/types" class="phi-media">
                    <mu-icon value="insert_drive_file" class="phi-media-figure" />
                    <span class="phi-media-body">{{ $t("noun.postTypes") }}</span>
                </router-link>
                -->
				<router-link to="/settings" class="phi-media">
                    <mu-icon value="settings" class="phi-media-figure" />
                    <span class="phi-media-body">{{ $t("noun.settings") }}</span>
                    <mu-icon value="keyboard_arrow_right" class="phi-media-right" />
                </router-link>
			</div>
        </section>

        <footer>
            <p class="disclaimer" v-if="settings.disclaimerURL">
                <span>{{ $t('login.disclaimer') }}</span>
                <a href="#" @click.prevent="window.open(settings.disclaimerURL, '_system')"> {{ $t('here') }}</a>
            </p>

            <div @click="openHidden">
                <p v-if="$store.state.version">v{{ $store.state.version }}</p>
                <p>{{ $t('credits.l1') }}</p>
                <p v-if="settings.organization">{{ $t('credits.l2') }} {{ settings.organization.name }}</p>
                <small> {{ $t('credits.l3') }} &copy; 2005 - {{ year }}</small>
            </div>
        </footer>

    </phi-page>
</template>

<script>
import md5 from 'blueimp-md5';
import PersonAvatar from '@/components/Phi/Person/Avatar.vue';

export default {
    components: {PersonAvatar},

    data() {
        return {
            window,
            hiddenClicks: 7,
            year: new Date().getFullYear(),
            drawers: {
                messaging: false,
                transport: false
            }
        }
    },

	methods: {
		openHidden() {
			if (--this.hiddenClicks == 0) {
				this.$router.push("/hidden");
			}
        },

        toggleDrawer(drawerName, forceValue = null) {
            if (typeof this.drawers[drawerName] == "undefined") {
                return;
            }
            this.drawers[drawerName] = forceValue !== null ? forceValue : !this.drawers[drawerName];
        }
    },

    computed: {
        customLinks() {
            return this.$store.state.settings && this.$store.state.settings.StateLauncher && this.$store.state.settings.StateLauncher.links ? this.$store.state.settings.StateLauncher.links : [];
        },

        // blockLinks() {
        //     return this.$store.state.settings && this.$store.state.settings.StateLauncher && this.$store.state.settings.StateLauncher.blocks ? this.$store.state.settings.StateLauncher.blocks : [];
        // },

        deck() {
            return this.$parent;
        },

        user() {
            return this.$store.state.user;
        },

        settings() {
            return this.$store.state.settings ? this.$store.state.settings : {};
        },

		locationUrl() {
            if (!this.settings.organization || !this.settings.organization.latitude) {
                return;
            }

            let deviceType = (navigator.userAgent.match(/iPad/i))  == "iPad" ? "iPad" :
                (navigator.userAgent.match(/iPhone/i))  == "iPhone" ? "iPhone" :
                (navigator.userAgent.match(/Android/i)) == "Android" ? "Android" :
                (navigator.userAgent.match(/BlackBerry/i)) == "BlackBerry" ? "BlackBerry" : "null";
			//console.log(deviceType);
            // if (!window.device || !window.device.platform || window.device.platform != 'Android') {
			// 	return `http://maps.apple.com/?ll=${this.settings.organization.latitude},${this.settings.organization.longitude}`;
			// }
            if (deviceType == "iPad" || deviceType == "iPhone") {
                return `http://maps.apple.com/?ll=${this.settings.organization.latitude},${this.settings.organization.longitude}`;
            }
            if (deviceType == "Android") {
                return `geo:${this.settings.organization.latitude},${this.settings.organization.longitude}`;
            }
            return `http://maps.google.com/maps?&z=10&q=${this.settings.organization.latitude}+${this.settings.organization.longitude}&ll=${this.settings.organization.latitude}+${this.settings.organization.longitude}`;
        },

        phidiasUrl() {
            let baseUrl = this.$store.state.url.replace(".api", "");
			let time = Math.round((new Date()).getTime()/1000);
			let tli = this.user.id;
			let tld = time + 604800;
            let tlh = md5(`!speak FR13nD and enter:${tli}@${tld} ${baseUrl.replace("https://", "")}`);

			return `${baseUrl}?tli=${tli}&tld=${tld}&tlh=${tlh}`;
		}
    },

    i18n: {
        "en": {
            "launcher.teaching": "Teaching",
            "launcher.classroom": "Classroom",
        },

        "es": {
            "launcher.teaching": "Docencia",
            "launcher.classroom": "Aula",
        }
    }
}
</script>

<style lang="scss">
$default-bg-color: rgb(28, 137, 184);

.page-launcher {
	// background-image: url('../assets/cover.jpg');
    background-color: $default-bg-color;

    section {
        margin-bottom: 16px;

        label {
            font-size: 11px;
            color: #777;
            padding: 6px 4px;
        }
    }

    section.header {
        margin-top: -48px;

        .logo {
            text-align: center;
            padding: 8px;

            img {
                background-color: $default-bg-color;
                max-width: 100%;
            }
        }

        h1 {
            font-size: 1.4em;
            align-self: center;
        }
    }

    footer {
        margin: 32px 0 32px 0;
        text-align: center;
        font-size: 11px;
        color: #999;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }

    .disclaimer {
        padding: 12px;
        margin-bottom: 12px;
		font-size: 12px;
        font-weight: 300;
        text-align: center;
        color: #777;
		a {
			color: #5091e0;
			text-decoration: underline;
		}
	}


    a {
        .phi-media-right {
            opacity: .5;
        }
    }

    .submenu {
        border-left: 3px solid #ccc;
        padding-bottom: 20px;

        &.last {
            padding-bottom: 0;
        }
    }
}
</style>
